@page {
  size: 80mm 100mm;
  margin: 0;
}

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }

  .pdf-wrapper {
    /* margin: 0 5px; */
    font-size: 10px;
    /* position: relative; */
  
    /* display: flex; */
    /* flex-direction: column; */
    /* height: 95vh; */
  }
  
  .pdf-main {
    flex: 1;
  }
  .pdf-content {
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
  }
  
  .pdf-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
  }
  
  .pdf .logo {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .pdf .address {
    /* line-height: 1.5; */
    padding-top: 10px;
    font-size: 8px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .pdf .header-title {
    padding-top: 10px;
    flex: 0 0 60%;
    max-width: 60%;
    text-align: center;
  }
  
  .pdf .section-header {
    background: linear-gradient(101deg, #cce6f1, #f1f8f1);
    padding: 1px;
    /* padding-left: 7px; */
    /* margin-bottom: 3px; */
  }
  .pdf .section-header h2 {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    margin: 0;
  }
  
  .pdf .section-content {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .pdf .section-meta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .pdf .section-meta-item {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: space-between;
  }
  .pdf .section label {
    line-height: 1.2;
    font-weight: 700;
  }
  
  .pdf .section p {
    margin: 0;
    text-align: right;
    line-height: 1.2;
  }
  
  .pdf .section-meta-item-fullwidth {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .pdf .section label {
    font-weight: 700;
  }
  
  p {
    margin: 0;
    line-height: 1.2;
  }
  
  .pdf .section img {
    margin-bottom: 5px;
  }
  
  .pdf .section label {
    font-weight: 700;
    font-size: 10px;
  }
  
  p {
    margin: 0;
    text-align: right;
    font-size: 10px;
  }
  
  .pdf .section .spacing {
    border: solid 5px transparent;
    box-sizing: border-box;
    position: relative;
  }
  .pdf .section .spacing::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  