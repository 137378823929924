body { font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  'Siemreap', 'Roboto' }

.pizzainvoice {
  /* padding: 0px 10px 10px 10px; */
  /* width: 559px; */
  margin-top: 10px;
  padding: 0px 0px 10px 10px;
  width: 337px;
  /* width: 489px; */
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.pizzainvoice table {
  width: 100%;
}

.pizzaTable table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

.pizzaTable th {
  font-size: 11px;
  padding: 1px 5px;
  background-color: rgba(128, 128, 128, 0.4);
  border: 1px solid black;
}

.pizzaTable td {
  font-size: 11px;
  padding: 2px 5px;
  border: 1px solid gray;
  text-align: left;
}

.pageHeader, .pageHeaderSpace {
  padding-top: 10px;
  height: 110px;
  top: 10;
}
.pageHeader {
  margin-top: 2px;
  position: fixed;
  /* width: 447px; */
  width: 550px;
  top: 10;
  font-size: 12px;
}
.pageHeader {
  word-wrap: break-word;
  word-break: break-all;
}

.pageFooter, .pageFooterSpace {
  height: 50px;
}
.pageFooter {
  position: fixed;
  width: 447px;
  bottom: 0;
}

.page {
  page-break-after: always;
}


.stickerPage {
  /* width: 300px; */
  width: 213px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* margin: 75px auto auto 100px; */
  margin: 120px auto auto 10px;
}

.stickerPage h3 {
  word-wrap: break-word;
  word-break: break-all;
}

.stickerPage .firstSection div:first-child {
  padding: 2px;
  border: 1px solid black;
}

.stickerPage .firstSection div {
  padding: 2px;
  border: 1px solid black;
  border-top: none;
}

.stickerPage .secondSection div:first-child {
  padding: 2px;
  margin-top: 30px;
  border: none;
  border-bottom: 1px solid black;
}

.stickerPage .secondSection div {
  padding: 2px;
  border: 1px solid black;
  border-top: none;
}

.alignCenter {
  text-align: center;
}