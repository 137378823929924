.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}

.print {
  background-color: rgb(93, 93, 93);
}

/* .site-button-ghost-wrapper {
  border-radius: 6px;
  background: rgb(2, 150, 203);
} */

.printed {
  background-color: rgb(51, 197, 143, 33%);
}

.printedWithoutPayment {
  background-color: rgba(235, 255, 55, 0.657);
}

.printedWithPayment {
  background-image: linear-gradient(to right, hsl(122deg 58% 52% / 79%) , hsl(197deg 54% 42% / 62%));
}

.printedWithoutAddress {
  background-color: rgba(255, 33, 33, 0.657);
}

.dropdown-content {
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.dropdown-content .ant-dropdown-menu {
  box-shadow: none;
}