.site-page-header {
  border-radius: 6px;
  background-color:#291400;
  color: white;
  margin-bottom: 10px;
}

.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgb(255 255 255 / 85%) !important;
  font-weight: 400 !important;
  /* font-size: 18px; */
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .ant-menu-item-selected {
  background-color: #7FA0A2 !important;
  color: white !important;
}

.ant-menu-item-active {
  color: rgb(209, 209, 209) !important;
} */
